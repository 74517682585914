<template>
  <a-row :gutter="24" type="flex">

    <a-col :span="24" :md="4" class="mb-24">
      <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="game-upload-form"
          @submit="handleSubmit"
      >
        <a-form-item class="mb-10">
          <a-textarea
              v-decorator="[
                          'block_user',
                          { rules: [{ required: true, message: 'Please input block user list!' }] },
                        ]"
              :auto-size="{ minRows: 10 }"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" block html-type="submit" class="login-form-button">
            <a-spin v-if="confirmLoading"/>
            Submit
          </a-button>
        </a-form-item>
      </a-form>
    </a-col>
    <a-modal
        v-model:modalOpen="modalOpen"
        title="Message"
        @ok="closeModal"
    >
      <h4>Users are successfully unsubscribed and blocked!</h4>
    </a-modal>
  </a-row>
</template>

<script>
import {sendRequest} from '@/http/axios.method';

export default {
  name: "BlockUser",
  data() {
    return {
      config: {
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      },
      confirmLoading: false,
      modalOpen: false,
    }
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, {name: 'block_user'});
  },
  methods: {
    closeModal() {
      this.modalOpen = false;
      window.location.reload();
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true;
          let data = {
            users_phone: ((values.block_user).split("\n")).filter(n => n)
          }
          sendRequest('post', 'admin/block-user', data, this.config)
              .then(response => {
                if (response.success) {
                  this.confirmLoading = false;
                  this.modalOpen = true;
                } else {
                  if (response.status_code === 401) {
                    this.$store.dispatch('logout');
                    this.$router.push('/login');
                  }
                }

              })
              .catch(() => {
                this.confirmLoading = false;
              })
              .finally(() => {
                this.confirmLoading = false;
              })
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
